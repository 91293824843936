<template>
  <ClientOnly v-if="usefulLabels.length ">
    <div 
      class="realtime-type"
    >
      <div class="swiper-wrapper">
        <LabelWithSwiper
          v-if="isActive"
          :labels="usefulLabels"
          :style-config="{ imgSize: 16 }"
          :cart-leave="cartLeave"
          @expose="onExpose"
        />
      </div>
    </div>
  </ClientOnly>
</template>

<script>
import LabelWithSwiper from './LabelWithSwiper.vue'
import ClientOnly from 'vue-client-only'
import { template } from '@shein/common-function'
import { initRealTimeScrollFn } from './utils.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  name: 'RealtimeType',
  components: {
    ClientOnly,
    LabelWithSwiper,
  },
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    labels: {
      type: Array,
      default: () => ([])
    },
    eleInfo: {
      type: Object,
      default() {
        return {
          headerEle: '',
          scrollEle: '',
        }
      }
    },
    cartLeave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      exposeIds: [],

      scrollFn: null,
      isActive: true,
    }
  },
  computed: {
    // 实际展示的标签
    usefulLabels() {
      return this.labels?.filter((v) => v.showLabelTag).map((v) => ({
        ...v,
        labelText: this.getItemText(v),
        imgSrc: v.goodsImg,
      }))
    }
  },
  watch: {
    usefulLabels: {
      handler(n) {
        if (n.length) {
          this.reloadComponent()
        }
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initScroll()
    })
  },
  beforeDestroy() {
    this.leave()
  },
  deactivated() {
    this.leave()
  },
  methods: {
    reloadComponent() {
      this.isActive = false
      this.$nextTick(() => {
        this.isActive = true
      })
    },
    getItemText(item) {
      const { labelId, nickName, langKey, showLabel } = item
      if (labelId == '3878') {
        return template(nickName, showLabel?.text, this.language[langKey])
      }

      if (labelId == '4394') {
        const str = `
          <i 
            class="suiiconfont sui_icon_star_5_15px_2" 
            style="color:#facf19;display:inline-block;height:16px;line-height:16px;vertical-align:bottom;">
          </i>
        `
        return template(nickName, str, showLabel?.text?.[0], this.language[langKey])
      }
    },
    
    // 滚动的交互处理
    initScroll() {
      if (!this.usefulLabels.length) return
      const { scrollEle, headerEle } = this.eleInfo
      
      const canScroll = scrollEle 
        ? $(scrollEle)?.[0]?.scrollHeight > $(scrollEle)?.[0]?.clientHeight 
        : document.documentElement.scrollHeight > window.innerHeight

      if (!canScroll) {
        $(this.$el).css('height', '1.1733rem')
        $(this.$el).css('opacity', 1)
      }
      const fn = initRealTimeScrollFn($(headerEle)?.[0], this.$el)
      this.scrollFn = (e) => fn(e)
      
      if (scrollEle) {
        $(scrollEle)?.[0]?.addEventListener('scroll', this.scrollFn, true)
      } else {
        document.addEventListener('scroll', this.scrollFn, true)
      }
    },
    // 埋点处理
    onExpose({ item }) {
      if (!item?.labelId || this.exposeIds.includes(item?.labelId)) return
      this.exposeIds.push(item.labelId)
    },
    leave() {
      // 配置了abt则用实际配置的标签，否则使用全部标签
      const reportArr = !!this.usefulLabels.length ? this.usefulLabels : this.labels
      if (!reportArr.length) return
      daEventCenter.triggerNotice({
        daId: '1-8-1-41',
        extraData: {
          userbehavior_tips: Array.from(new Set(reportArr.map((v) => v.labelId))).join(','),
          userbehavior_tips_show: this.exposeIds.join(','),
        }
      })
      this.reset()
    },

    reset() {
      this.exposeIds = []
    }
  },
}
</script>

<style lang="less" scoped>
.realtime-type {
  position: relative;
  z-index: 9;
  height: 0px;
  opacity: 0;
  padding: 0 12/37.5rem;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
}
.swiper-wrapper {
  display: inline-flex;
  align-items: center;
  width: auto;
  min-width: 120/37.5rem;
  height: 24/37.5rem;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 4/37.5rem 8/37.5rem;
  background: @sui_color_micro_emphasis_bg; 
}
</style>
