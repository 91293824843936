
import { throttle } from '@shein/common-function'

export const  animateStart = (configs, duration = 300) => {
  configs.forEach(item => {
    $(item.attr).animate(item.style, duration, 'linear')
  })
}

export const initRealTimeScrollFn = (headerEl, selfEl) => {
  const headerElHeight = $(headerEl).css('height')
  const selfElHeight = '1.1733rem'
  let lastScrollPosition = 0
  let lastDirection = 'up'
  return throttle({
    func: (e) => {
      const currentScrollPosition = e?.target?.scrollTop || document.documentElement.scrollTop
      const scrollDirection = currentScrollPosition > lastScrollPosition ? 'down' : 'up'
      if (scrollDirection === 'down' && lastDirection !== 'down') {
        animateStart([
          
          { attr: selfEl, style: { opacity: 1, height: selfElHeight } },
          { attr: headerEl, style: { opacity: 0, height: 0, border: 'none' } },
        ], 100)
      }
      if (scrollDirection === 'up' && lastDirection !== 'up') {
        animateStart([
          { attr: headerEl, style: { opacity: 1, height: headerElHeight, border: '' } },
          { attr: selfEl, style: { opacity: 0, height: 0 } },
        ], 100)
      }
      lastScrollPosition = currentScrollPosition
      lastDirection = scrollDirection
    },
    wait: 200,
  })
}
